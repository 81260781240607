import { useState, useEffect, useRef} from 'react';
import { useGetLevelValuesQuery } from '../../store/apis/levelValueApi';
import { activateSpinner, deactivateSpinner } from '../../redux/spinnerslice'
import { activateErrorPage, deactivateErrorPage } from '../../redux/errorPageSlice';
import SEAutoSuggestInput from '../../components/SimulationEngine/SEAutoSuggestInput';
import { useDispatch, useSelector } from 'react-redux'
import {setStep} from '../../redux/simulationStepSlice';
import helper from '../../utils/Helper';
import { set } from 'lodash';
import {SIMULATION_ENGINE_STEPS, PROGRESS_BAR_STEPS} from '../../utils/Constants'

const ProgramDomain = () => {


    const dispatch = useDispatch()
    let user = helper.getLocalStorageWithExpiration('wendogouser') 
    const [programDomainObj, setProgramDomainObj] = useState(user?.programDomainObj || null)
    console.log('programDomainObj', programDomainObj?.name)
    const [urlFragment] = useState('/levelvalue/search/');
    const simulationStepGlobal = useSelector((state) => state.simulationStep);
    const progressBarStep = useSelector((state) => state.progressBarStep);
    const titleWhenNoMatch = "Quel Domaine d'études correspond le mieux à cette formation ?"; 
    const { data, error, isLoading } = useGetLevelValuesQuery({userid : '1', externalLevelValueInput : user?.degreeExactNameValue});

    let showContinueBtn 
    if(user?.isResult3Available){
        console.log('OOOO 1')
        showContinueBtn = simulationStepGlobal === SIMULATION_ENGINE_STEPS.PROGRAM_DOMAIN
    }else if(!user?.isResult3Available && user?.isResult2Available && progressBarStep === PROGRESS_BAR_STEPS.BULLETIN_N_1){
        console.log('OOOO 2')
        showContinueBtn = simulationStepGlobal === SIMULATION_ENGINE_STEPS.PROGRAM_DOMAIN_BAC_N_1
    }else if(!user?.isResult2Available && user?.isResult1Available && progressBarStep === PROGRESS_BAR_STEPS.BULLETIN_N_2){
        console.log('OOOO 3')
        showContinueBtn = simulationStepGlobal === SIMULATION_ENGINE_STEPS.PROGRAM_DOMAIN_BAC_N_2
    }

    useEffect(() => {
        if(isLoading){
            dispatch(activateSpinner())
        }
        if(error){
            console.error('🛑 error', error)
            dispatch(deactivateSpinner()) 
            dispatch(activateErrorPage())           
        }
        if (data) {
            dispatch(deactivateSpinner())
            dispatch(deactivateErrorPage())
            //console.log('matching domain', data.best_match)  
            // console.log('matching data 00', data)
            
        }
    }, [data, error, isLoading ])
    const handleChange = (value) => {
        const inputValue = value;
        setProgramDomainObj(value);
        console.log('inputValue', inputValue)
        // setDegreeExactNameValue(inputValue);
         updateWendogouser(SIMULATION_ENGINE_STEPS.PROGRAM_DOMAIN, inputValue)
    };   

    const handleContinue = () => {
        let NEXT_EFFECTIVE_STEP  
        
        if(user?.isResult3Available){
            NEXT_EFFECTIVE_STEP = SIMULATION_ENGINE_STEPS.MAIN_SUBJECTS
        }else if(!user?.isResult3Available && progressBarStep === PROGRESS_BAR_STEPS.BULLETIN_N_1){
            NEXT_EFFECTIVE_STEP = SIMULATION_ENGINE_STEPS.MAIN_SUBJECTS_BAC_N_1
        }else if(!user?.isResult2Available && progressBarStep === PROGRESS_BAR_STEPS.BULLETIN_N_2){
            NEXT_EFFECTIVE_STEP = SIMULATION_ENGINE_STEPS.MAIN_SUBJECTS_BAC_N_2
        }
        updateWendogouser(NEXT_EFFECTIVE_STEP, programDomainObj ? programDomainObj : data?.best_match)
    }

    const updateWendogouser = (simulationStep, programDomainObj) => {
        dispatch(setStep(simulationStep)) 
        let updatedUser = {...user, simulationStep, programDomainObj, date: new Date().toISOString()}
        helper.setLocalStorageWithExpiration('wendogouser', updatedUser)         
    }

    return (<>   
        {data?.hasOwnProperty('best_match') && 
                <SEAutoSuggestInput title={titleWhenNoMatch} id={`PROGRAM_DOMAIN${simulationStepGlobal}`}
                                    tip="Par exemple, des formations en droit civil, droit des affaires ou droit des contrats s'inscrivent dans le domaine d'études du droit."
                                    messageError="Un domaine d'études doit être sélectionné"
                                    matchingValue={programDomainObj || data.best_match}
                                    urlFragment ={urlFragment}
                                    showContinueBtn={showContinueBtn}
                                    handleChange={handleChange} 
                                    handleContinue={handleContinue}/>}</>
    );
}

export default ProgramDomain;
