const COUNTRY_CODES = 
[
    {
        "name": "Canada",
        "dialCode": "🇨🇦+1",
        "code": "CA"
    },
    {
        "name": "United States",
        "dialCode": "🇺🇸+1",
        "code": "US"
    },
    {
        "name": "Russia",
        "dialCode": "🇷🇺+7",
        "code": "RU"
    },
    {
        "name": "Egypt",
        "dialCode": "🇪🇬+20",
        "code": "EG"
    },
    {
        "name": "South Africa",
        "dialCode": "🇿🇦+27",
        "code": "ZA"
    },
    {
        "name": "Greece",
        "dialCode": "🇬🇷+30",
        "code": "GR"
    },
    {
        "name": "Netherlands",
        "dialCode": "🇳🇱+31",
        "code": "NL"
    },
    {
        "name": "Belgium",
        "dialCode": "🇧🇪+32",
        "code": "BE"
    },
    {
        "name": "France",
        "dialCode": "🇫🇷+33",
        "code": "FR"
    },
    {
        "name": "Spain",
        "dialCode": "🇪🇸+34",
        "code": "ES"
    },
    {
        "name": "Hungary",
        "dialCode": "🇭🇺+36",
        "code": "HU"
    },
    {
        "name": "Italy",
        "dialCode": "🇮🇹+39",
        "code": "IT"
    },
    {
        "name": "Romania",
        "dialCode": "🇷🇴+40",
        "code": "RO"
    },
    {
        "name": "Switzerland",
        "dialCode": "🇨🇭+41",
        "code": "CH"
    },
    {
        "name": "Austria",
        "dialCode": "🇦🇹+43",
        "code": "AT"
    },
    {
        "name": "Guernsey",
        "dialCode": "🇬🇬+44",
        "code": "GG"
    },
    {
        "name": "Isle of Man",
        "dialCode": "🇮🇲+44",
        "code": "IM"
    },
    {
        "name": "Jersey",
        "dialCode": "🇯🇪+44",
        "code": "JE"
    },
    {
        "name": "United Kingdom",
        "dialCode": "🇬🇧+44",
        "code": "GB"
    },
    {
        "name": "Denmark",
        "dialCode": "🇩🇰+45",
        "code": "DK"
    },
    {
        "name": "Sweden",
        "dialCode": "🇸🇪+46",
        "code": "SE"
    },
    {
        "name": "Norway",
        "dialCode": "🇳🇴+47",
        "code": "NO"
    },
    {
        "name": "Svalbard and Jan Mayen",
        "dialCode": "🇸🇯+47",
        "code": "SJ"
    },
    {
        "name": "Poland",
        "dialCode": "🇵🇱+48",
        "code": "PL"
    },
    {
        "name": "Germany",
        "dialCode": "🇩🇪+49",
        "code": "DE"
    },
    {
        "name": "Peru",
        "dialCode": "🇵🇪+51",
        "code": "PE"
    },
    {
        "name": "Mexico",
        "dialCode": "🇲🇽+52",
        "code": "MX"
    },
    {
        "name": "Cuba",
        "dialCode": "🇨🇺+53",
        "code": "CU"
    },
    {
        "name": "Argentina",
        "dialCode": "🇦🇷+54",
        "code": "AR"
    },
    {
        "name": "Brazil",
        "dialCode": "🇧🇷+55",
        "code": "BR"
    },
    {
        "name": "Chile",
        "dialCode": "🇨🇱+56",
        "code": "CL"
    },
    {
        "name": "Colombia",
        "dialCode": "🇨🇴+57",
        "code": "CO"
    },
    {
        "name": "Venezuela, Bolivarian Republic of Venezuela",
        "dialCode": "🇻🇪+58",
        "code": "VE"
    },
    {
        "name": "Malaysia",
        "dialCode": "🇲🇾+60",
        "code": "MY"
    },
    {
        "name": "Australia",
        "dialCode": "🇦🇺+61",
        "code": "AU"
    },
    {
        "name": "Christmas Island",
        "dialCode": "🇨🇽+61",
        "code": "CX"
    },
    {
        "name": "Cocos (Keeling) Islands",
        "dialCode": "🇨🇨+61",
        "code": "CC"
    },
    {
        "name": "Indonesia",
        "dialCode": "🇮🇩+62",
        "code": "ID"
    },
    {
        "name": "Philippines",
        "dialCode": "🇵🇭+63",
        "code": "PH"
    },
    {
        "name": "New Zealand",
        "dialCode": "🇳🇿+64",
        "code": "NZ"
    },
    {
        "name": "Singapore",
        "dialCode": "🇸🇬+65",
        "code": "SG"
    },
    {
        "name": "Thailand",
        "dialCode": "🇹🇭+66",
        "code": "TH"
    },
    {
        "name": "Kazakhstan",
        "dialCode": "🇰🇿+77",
        "code": "KZ"
    },
    {
        "name": "Japan",
        "dialCode": "🇯🇵+81",
        "code": "JP"
    },
    {
        "name": "Korea, Republic of South Korea",
        "dialCode": "🇰🇷+82",
        "code": "KR"
    },
    {
        "name": "Vietnam",
        "dialCode": "🇻🇳+84",
        "code": "VN"
    },
    {
        "name": "China",
        "dialCode": "🇨🇳+86",
        "code": "CN"
    },
    {
        "name": "Turkey",
        "dialCode": "🇹🇷+90",
        "code": "TR"
    },
    {
        "name": "India",
        "dialCode": "🇮🇳+91",
        "code": "IN"
    },
    {
        "name": "Pakistan",
        "dialCode": "🇵🇰+92",
        "code": "PK"
    },
    {
        "name": "Sri Lanka",
        "dialCode": "🇱🇰+94",
        "code": "LK"
    },
    {
        "name": "Myanmar",
        "dialCode": "🇲🇲+95",
        "code": "MM"
    },
    {
        "name": "Iran, Islamic Republic of Persian Gulf",
        "dialCode": "🇮🇷+98",
        "code": "IR"
    },
    {
        "name": "Morocco",
        "dialCode": "🇲🇦+212",
        "code": "MA"
    },
    {
        "name": "Algeria",
        "dialCode": "🇩🇿+213",
        "code": "DZ"
    },
    {
        "name": "Tunisia",
        "dialCode": "🇹🇳+216",
        "code": "TN"
    },
    {
        "name": "Libyan Arab Jamahiriya",
        "dialCode": "🇱🇾+218",
        "code": "LY"
    },
    {
        "name": "Gambia",
        "dialCode": "🇬🇲+220",
        "code": "GM"
    },
    {
        "name": "Senegal",
        "dialCode": "🇸🇳+221",
        "code": "SN"
    },
    {
        "name": "Mauritania",
        "dialCode": "🇲🇷+222",
        "code": "MR"
    },
    {
        "name": "Mali",
        "dialCode": "🇲🇱+223",
        "code": "ML"
    },
    {
        "name": "Guinea",
        "dialCode": "🇬🇳+224",
        "code": "GN"
    },
    {
        "name": "Cote d'Ivoire",
        "dialCode": "🇨🇮+225",
        "code": "CI"
    },
    {
        "name": "Burkina Faso",
        "dialCode": "🇧🇫+226",
        "code": "BF"
    },
    {
        "name": "Niger",
        "dialCode": "🇳🇪+227",
        "code": "NE"
    },
    {
        "name": "Togo",
        "dialCode": "🇹🇬+228",
        "code": "TG"
    },
    {
        "name": "Benin",
        "dialCode": "🇧🇯+229",
        "code": "BJ"
    },
    {
        "name": "Mauritius",
        "dialCode": "🇲🇺+230",
        "code": "MU"
    },
    {
        "name": "Liberia",
        "dialCode": "🇱🇷+231",
        "code": "LR"
    },
    {
        "name": "Sierra Leone",
        "dialCode": "🇸🇱+232",
        "code": "SL"
    },
    {
        "name": "Ghana",
        "dialCode": "🇬🇭+233",
        "code": "GH"
    },
    {
        "name": "Nigeria",
        "dialCode": "🇳🇬+234",
        "code": "NG"
    },
    {
        "name": "Chad",
        "dialCode": "🇹🇩+235",
        "code": "TD"
    },
    {
        "name": "Central African Republic",
        "dialCode": "🇨🇫+236",
        "code": "CF"
    },
    {
        "name": "Cameroon",
        "dialCode": "🇨🇲+237",
        "code": "CM"
    },
    {
        "name": "Cape Verde",
        "dialCode": "🇨🇻+238",
        "code": "CV"
    },
    {
        "name": "Sao Tome and Principe",
        "dialCode": "🇸🇹+239",
        "code": "ST"
    },
    {
        "name": "Equatorial Guinea",
        "dialCode": "🇬🇶+240",
        "code": "GQ"
    },
    {
        "name": "Gabon",
        "dialCode": "🇬🇦+241",
        "code": "GA"
    },
    {
        "name": "Congo",
        "dialCode": "🇨🇬+242",
        "code": "CG"
    },
    {
        "name": "Congo, The Democratic Republic of the Congo",
        "dialCode": "🇨🇩+243",
        "code": "CD"
    },
    {
        "name": "Angola",
        "dialCode": "🇦🇴+244",
        "code": "AO"
    },
    {
        "name": "Guinea-Bissau",
        "dialCode": "🇬🇼+245",
        "code": "GW"
    },
    {
        "name": "British Indian Ocean Territory",
        "dialCode": "🇮🇴+246",
        "code": "IO"
    },
    {
        "name": "Seychelles",
        "dialCode": "🇸🇨+248",
        "code": "SC"
    },
    {
        "name": "Sudan",
        "dialCode": "🇸🇩+249",
        "code": "SD"
    },
    {
        "name": "Rwanda",
        "dialCode": "🇷🇼+250",
        "code": "RW"
    },
    {
        "name": "Ethiopia",
        "dialCode": "🇪🇹+251",
        "code": "ET"
    },
    {
        "name": "Somalia",
        "dialCode": "🇸🇴+252",
        "code": "SO"
    },
    {
        "name": "Djibouti",
        "dialCode": "🇩🇯+253",
        "code": "DJ"
    },
    {
        "name": "Kenya",
        "dialCode": "🇰🇪+254",
        "code": "KE"
    },
    {
        "name": "Tanzania, United Republic of Tanzania",
        "dialCode": "🇹🇿+255",
        "code": "TZ"
    },
    {
        "name": "Uganda",
        "dialCode": "🇺🇬+256",
        "code": "UG"
    },
    {
        "name": "Burundi",
        "dialCode": "🇧🇮+257",
        "code": "BI"
    },
    {
        "name": "Mozambique",
        "dialCode": "🇲🇿+258",
        "code": "MZ"
    },
    {
        "name": "Zambia",
        "dialCode": "🇿🇲+260",
        "code": "ZM"
    },
    {
        "name": "Madagascar",
        "dialCode": "🇲🇬+261",
        "code": "MG"
    },
    {
        "name": "French Southern Territories",
        "dialCode": "🇹🇫+262",
        "code": "TF"
    },
    {
        "name": "Mayotte",
        "dialCode": "🇾🇹+262",
        "code": "YT"
    },
    {
        "name": "Reunion",
        "dialCode": "🇷🇪+262",
        "code": "RE"
    },
    {
        "name": "Zimbabwe",
        "dialCode": "🇿🇼+263",
        "code": "ZW"
    },
    {
        "name": "Namibia",
        "dialCode": "🇳🇦+264",
        "code": "NA"
    },
    {
        "name": "Malawi",
        "dialCode": "🇲🇼+265",
        "code": "MW"
    },
    {
        "name": "Lesotho",
        "dialCode": "🇱🇸+266",
        "code": "LS"
    },
    {
        "name": "Botswana",
        "dialCode": "🇧🇼+267",
        "code": "BW"
    },
    {
        "name": "Swaziland",
        "dialCode": "🇸🇿+268",
        "code": "SZ"
    },
    {
        "name": "Comoros",
        "dialCode": "🇰🇲+269",
        "code": "KM"
    },
    {
        "name": "Saint Helena, Ascension and Tristan Da Cunha",
        "dialCode": "🇸🇭+290",
        "code": "SH"
    },
    {
        "name": "Eritrea",
        "dialCode": "🇪🇷+291",
        "code": "ER"
    },
    {
        "name": "Aruba",
        "dialCode": "🇦🇼+297",
        "code": "AW"
    },
    {
        "name": "Faroe Islands",
        "dialCode": "🇫🇴+298",
        "code": "FO"
    },
    {
        "name": "Greenland",
        "dialCode": "🇬🇱+299",
        "code": "GL"
    },
    {
        "name": "Cayman Islands",
        "dialCode": "🇰🇾+345",
        "code": "KY"
    },
    {
        "name": "Gibraltar",
        "dialCode": "🇬🇮+350",
        "code": "GI"
    },
    {
        "name": "Portugal",
        "dialCode": "🇵🇹+351",
        "code": "PT"
    },
    {
        "name": "Luxembourg",
        "dialCode": "🇱🇺+352",
        "code": "LU"
    },
    {
        "name": "Ireland",
        "dialCode": "🇮🇪+353",
        "code": "IE"
    },
    {
        "name": "Iceland",
        "dialCode": "🇮🇸+354",
        "code": "IS"
    },
    {
        "name": "Albania",
        "dialCode": "🇦🇱+355",
        "code": "AL"
    },
    {
        "name": "Malta",
        "dialCode": "🇲🇹+356",
        "code": "MT"
    },
    {
        "name": "Cyprus",
        "dialCode": "🇨🇾+357",
        "code": "CY"
    },
    {
        "name": "Finland",
        "dialCode": "🇫🇮+358",
        "code": "FI"
    },
    {
        "name": "Bulgaria",
        "dialCode": "🇧🇬+359",
        "code": "BG"
    },
    {
        "name": "Lithuania",
        "dialCode": "🇱🇹+370",
        "code": "LT"
    },
    {
        "name": "Latvia",
        "dialCode": "🇱🇻+371",
        "code": "LV"
    },
    {
        "name": "Estonia",
        "dialCode": "🇪🇪+372",
        "code": "EE"
    },
    {
        "name": "Moldova",
        "dialCode": "🇲🇩+373",
        "code": "MD"
    },
    {
        "name": "Armenia",
        "dialCode": "🇦🇲+374",
        "code": "AM"
    },
    {
        "name": "Belarus",
        "dialCode": "🇧🇾+375",
        "code": "BY"
    },
    {
        "name": "Andorra",
        "dialCode": "🇦🇩+376",
        "code": "AD"
    },
    {
        "name": "Monaco",
        "dialCode": "🇲🇨+377",
        "code": "MC"
    },
    {
        "name": "San Marino",
        "dialCode": "🇸🇲+378",
        "code": "SM"
    },
    {
        "name": "Ukraine",
        "dialCode": "🇺🇦+380",
        "code": "UA"
    },
    {
        "name": "Serbia",
        "dialCode": "🇷🇸+381",
        "code": "RS"
    },
    {
        "name": "Montenegro",
        "dialCode": "🇲🇪+382",
        "code": "ME"
    },
    {
        "name": "Croatia",
        "dialCode": "🇭🇷+385",
        "code": "HR"
    },
    {
        "name": "Slovenia",
        "dialCode": "🇸🇮+386",
        "code": "SI"
    },
    {
        "name": "Bosnia and Herzegovina",
        "dialCode": "🇧🇦+387",
        "code": "BA"
    },
    {
        "name": "Macedonia",
        "dialCode": "🇲🇰+389",
        "code": "MK"
    },
    {
        "name": "Czech Republic",
        "dialCode": "🇨🇿+420",
        "code": "CZ"
    },
    {
        "name": "Slovakia",
        "dialCode": "🇸🇰+421",
        "code": "SK"
    },
    {
        "name": "Liechtenstein",
        "dialCode": "🇱🇮+423",
        "code": "LI"
    },
    {
        "name": "Falkland Islands (Malvinas)",
        "dialCode": "🇫🇰+500",
        "code": "FK"
    },
    {
        "name": "South Georgia and the South Sandwich Islands",
        "dialCode": "🇬🇸+500",
        "code": "GS"
    },
    {
        "name": "Belize",
        "dialCode": "🇧🇿+501",
        "code": "BZ"
    },
    {
        "name": "Guatemala",
        "dialCode": "🇬🇹+502",
        "code": "GT"
    },
    {
        "name": "El Salvador",
        "dialCode": "🇸🇻+503",
        "code": "SV"
    },
    {
        "name": "Honduras",
        "dialCode": "🇭🇳+504",
        "code": "HN"
    },
    {
        "name": "Nicaragua",
        "dialCode": "🇳🇮+505",
        "code": "NI"
    },
    {
        "name": "Costa Rica",
        "dialCode": "🇨🇷+506",
        "code": "CR"
    },
    {
        "name": "Panama",
        "dialCode": "🇵🇦+507",
        "code": "PA"
    },
    {
        "name": "Saint Pierre and Miquelon",
        "dialCode": "🇵🇲+508",
        "code": "PM"
    },
    {
        "name": "Haiti",
        "dialCode": "🇭🇹+509",
        "code": "HT"
    },
    {
        "name": "Guadeloupe",
        "dialCode": "🇬🇵+590",
        "code": "GP"
    },
    {
        "name": "Saint Barthelemy",
        "dialCode": "🇧🇱+590",
        "code": "BL"
    },
    {
        "name": "Saint Martin",
        "dialCode": "🇲🇫+590",
        "code": "MF"
    },
    {
        "name": "Bolivia, Plurinational State of",
        "dialCode": "🇧🇴+591",
        "code": "BO"
    },
    {
        "name": "Guyana",
        "dialCode": "🇬🇾+592",
        "code": "GY"
    },
    {
        "name": "Ecuador",
        "dialCode": "🇪🇨+593",
        "code": "EC"
    },
    {
        "name": "French Guiana",
        "dialCode": "🇬🇫+594",
        "code": "GF"
    },
    {
        "name": "Paraguay",
        "dialCode": "🇵🇾+595",
        "code": "PY"
    },
    {
        "name": "Martinique",
        "dialCode": "🇲🇶+596",
        "code": "MQ"
    },
    {
        "name": "Suriname",
        "dialCode": "🇸🇷+597",
        "code": "SR"
    },
    {
        "name": "Uruguay",
        "dialCode": "🇺🇾+598",
        "code": "UY"
    },
    {
        "name": "Netherlands Antilles",
        "dialCode": "🇦🇳+599",
        "code": "AN"
    },
    {
        "name": "Timor-Leste",
        "dialCode": "🇹🇱+670",
        "code": "TL"
    },
    {
        "name": "Norfolk Island",
        "dialCode": "🇳🇫+672",
        "code": "NF"
    },
    {
        "name": "Brunei Darussalam",
        "dialCode": "🇧🇳+673",
        "code": "BN"
    },
    {
        "name": "Nauru",
        "dialCode": "🇳🇷+674",
        "code": "NR"
    },
    {
        "name": "Papua New Guinea",
        "dialCode": "🇵🇬+675",
        "code": "PG"
    },
    {
        "name": "Tonga",
        "dialCode": "🇹🇴+676",
        "code": "TO"
    },
    {
        "name": "Solomon Islands",
        "dialCode": "🇸🇧+677",
        "code": "SB"
    },
    {
        "name": "Vanuatu",
        "dialCode": "🇻🇺+678",
        "code": "VU"
    },
    {
        "name": "Fiji",
        "dialCode": "🇫🇯+679",
        "code": "FJ"
    },
    {
        "name": "Palau",
        "dialCode": "🇵🇼+680",
        "code": "PW"
    },
    {
        "name": "Wallis and Futuna",
        "dialCode": "🇼🇫+681",
        "code": "WF"
    },
    {
        "name": "Cook Islands",
        "dialCode": "🇨🇰+682",
        "code": "CK"
    },
    {
        "name": "Niue",
        "dialCode": "🇳🇺+683",
        "code": "NU"
    },
    {
        "name": "Samoa",
        "dialCode": "🇼🇸+685",
        "code": "WS"
    },
    {
        "name": "Kiribati",
        "dialCode": "🇰🇮+686",
        "code": "KI"
    },
    {
        "name": "New Caledonia",
        "dialCode": "🇳🇨+687",
        "code": "NC"
    },
    {
        "name": "Tuvalu",
        "dialCode": "🇹🇻+688",
        "code": "TV"
    },
    {
        "name": "French Polynesia",
        "dialCode": "🇵🇫+689",
        "code": "PF"
    },
    {
        "name": "Tokelau",
        "dialCode": "🇹🇰+690",
        "code": "TK"
    },
    {
        "name": "Micronesia, Federated States of Micronesia",
        "dialCode": "🇫🇲+691",
        "code": "FM"
    },
    {
        "name": "Marshall Islands",
        "dialCode": "🇲🇭+692",
        "code": "MH"
    },
    {
        "name": "Korea, Democratic People's Republic of Korea",
        "dialCode": "🇰🇵+850",
        "code": "KP"
    },
    {
        "name": "Hong Kong",
        "dialCode": "🇭🇰+852",
        "code": "HK"
    },
    {
        "name": "Macao",
        "dialCode": "🇲🇴+853",
        "code": "MO"
    },
    {
        "name": "Cambodia",
        "dialCode": "🇰🇭+855",
        "code": "KH"
    },
    {
        "name": "Laos",
        "dialCode": "🇱🇦+856",
        "code": "LA"
    },
    {
        "name": "Pitcairn",
        "dialCode": "🇵🇳+872",
        "code": "PN"
    },
    {
        "name": "Bangladesh",
        "dialCode": "🇧🇩+880",
        "code": "BD"
    },
    {
        "name": "Taiwan",
        "dialCode": "🇹🇼+886",
        "code": "TW"
    },
    {
        "name": "Maldives",
        "dialCode": "🇲🇻+960",
        "code": "MV"
    },
    {
        "name": "Lebanon",
        "dialCode": "🇱🇧+961",
        "code": "LB"
    },
    {
        "name": "Jordan",
        "dialCode": "🇯🇴+962",
        "code": "JO"
    },
    {
        "name": "Syrian Arab Republic",
        "dialCode": "🇸🇾+963",
        "code": "SY"
    },
    {
        "name": "Iraq",
        "dialCode": "🇮🇶+964",
        "code": "IQ"
    },
    {
        "name": "Kuwait",
        "dialCode": "🇰🇼+965",
        "code": "KW"
    },
    {
        "name": "Saudi Arabia",
        "dialCode": "🇸🇦+966",
        "code": "SA"
    },
    {
        "name": "Yemen",
        "dialCode": "🇾🇪+967",
        "code": "YE"
    },
    {
        "name": "Oman",
        "dialCode": "🇴🇲+968",
        "code": "OM"
    },
    {
        "name": "Palestinian Territory, Occupied",
        "dialCode": "🇵🇸+970",
        "code": "PS"
    },
    {
        "name": "United Arab Emirates",
        "dialCode": "🇦🇪+971",
        "code": "AE"
    },
    {
        "name": "Israel",
        "dialCode": "🇮🇱+972",
        "code": "IL"
    },
    {
        "name": "Bahrain",
        "dialCode": "🇧🇭+973",
        "code": "BH"
    },
    {
        "name": "Qatar",
        "dialCode": "🇶🇦+974",
        "code": "QA"
    },
    {
        "name": "Bhutan",
        "dialCode": "🇧🇹+975",
        "code": "BT"
    },
    {
        "name": "Mongolia",
        "dialCode": "🇲🇳+976",
        "code": "MN"
    },
    {
        "name": "Nepal",
        "dialCode": "🇳🇵+977",
        "code": "NP"
    },
    {
        "name": "Tajikistan",
        "dialCode": "🇹🇯+992",
        "code": "TJ"
    },
    {
        "name": "Turkmenistan",
        "dialCode": "🇹🇲+993",
        "code": "TM"
    },
    {
        "name": "Azerbaijan",
        "dialCode": "🇦🇿+994",
        "code": "AZ"
    },
    {
        "name": "Georgia",
        "dialCode": "🇬🇪+995",
        "code": "GE"
    },
    {
        "name": "Kyrgyzstan",
        "dialCode": "🇰🇬+996",
        "code": "KG"
    },
    {
        "name": "Uzbekistan",
        "dialCode": "🇺🇿+998",
        "code": "UZ"
    },
    {
        "name": "Bahamas",
        "dialCode": "🇧🇸+1242",
        "code": "BS"
    },
    {
        "name": "Barbados",
        "dialCode": "🇧🇧+1246",
        "code": "BB"
    },
    {
        "name": "Virgin Islands, British",
        "dialCode": "🇻🇬+1284",
        "code": "VG"
    },
    {
        "name": "Virgin Islands, U.S.",
        "dialCode": "🇻🇮+1340",
        "code": "VI"
    },
    {
        "name": "Bermuda",
        "dialCode": "🇧🇲+1441",
        "code": "BM"
    },
    {
        "name": "Grenada",
        "dialCode": "🇬🇩+1473",
        "code": "GD"
    },
    {
        "name": "Turks and Caicos Islands",
        "dialCode": "🇹🇨+1649",
        "code": "TC"
    },
    {
        "name": "Montserrat",
        "dialCode": "🇲🇸+1664",
        "code": "MS"
    },
    {
        "name": "Northern Mariana Islands",
        "dialCode": "🇲🇵+1670",
        "code": "MP"
    },
    {
        "name": "Guam",
        "dialCode": "🇬🇺+1671",
        "code": "GU"
    },
    {
        "name": "American Samoa",
        "dialCode": "🇦🇸+1684",
        "code": "AS"
    },
    {
        "name": "Saint Lucia",
        "dialCode": "🇱🇨+1758",
        "code": "LC"
    },
    {
        "name": "Dominica",
        "dialCode": "🇩🇲+1767",
        "code": "DM"
    },
    {
        "name": "Saint Vincent and the Grenadines",
        "dialCode": "🇻🇨+1784",
        "code": "VC"
    },
    {
        "name": "Dominican Republic",
        "dialCode": "🇩🇴+1849",
        "code": "DO"
    },
    {
        "name": "Trinidad and Tobago",
        "dialCode": "🇹🇹+1868",
        "code": "TT"
    },
    {
        "name": "Saint Kitts and Nevis",
        "dialCode": "🇰🇳+1869",
        "code": "KN"
    },
    {
        "name": "Jamaica",
        "dialCode": "🇯🇲+1876",
        "code": "JM"
    },
    {
        "name": "Puerto Rico",
        "dialCode": "🇵🇷+1939",
        "code": "PR"
    }
]

export {COUNTRY_CODES}
